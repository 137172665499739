@import "https://fonts.googleapis.com/css2?family=Playfair+Display:wght@400;700&family=Raleway:ital,wght@0,300;0,400;0,700;1,300;1,400&display=swap";
html {
  box-sizing: border-box;
  -webkit-text-size-adjust: 100%;
  overflow-y: scroll;
}

*, :before, :after {
  box-sizing: inherit;
}

:before, :after {
  vertical-align: inherit;
  text-decoration: inherit;
}

* {
  background-repeat: no-repeat;
  margin: 0;
  padding: 0;
}

audio:not([controls]) {
  height: 0;
  display: none;
}

hr {
  overflow: visible;
}

article, aside, details, figcaption, figure, footer, header, main, menu, nav, section, summary {
  display: block;
}

small {
  font-size: 80%;
}

[hidden], template {
  display: none;
}

abbr[title] {
  border-bottom: 1px dotted;
  text-decoration: none;
}

a {
  -webkit-text-decoration-skip: objects;
  background-color: #0000;
}

a:active, a:hover {
  outline-width: 0;
}

code, kbd, pre, samp {
  font-family: monospace, monospace;
}

b, strong {
  font-weight: bolder;
}

dfn {
  font-style: italic;
}

mark {
  color: #000;
  background-color: #ff0;
}

sub, sup {
  vertical-align: baseline;
  font-size: 75%;
  line-height: 0;
  position: relative;
}

sub {
  bottom: -.25em;
}

sup {
  top: -.5em;
}

input {
  border-radius: 0;
}

[type="number"] {
  width: auto;
}

[type="search"] {
  -webkit-appearance: textfield;
  outline-offset: -2px;
}

[type="search"]::-webkit-search-cancel-button, [type="search"]::-webkit-search-decoration {
  -webkit-appearance: none;
}

textarea {
  resize: vertical;
  overflow: auto;
}

button, input, optgroup, select, textarea {
  font: inherit;
}

optgroup {
  font-weight: bold;
}

button {
  overflow: visible;
}

button::-moz-focus-inner, [type="button"]::-moz-focus-inner, [type="reset"]::-moz-focus-inner, [type="submit"]::-moz-focus-inner {
  border-style: 0;
  padding: 0;
}

button:-moz-focusring, [type="button"]::-moz-focus-inner, [type="reset"]::-moz-focus-inner, [type="submit"]::-moz-focus-inner {
  outline: 1px dotted ButtonText;
}

button, html [type="button"], [type="reset"], [type="submit"] {
  -webkit-appearance: button;
}

button, select {
  text-transform: none;
}

button, input, select, textarea {
  color: inherit;
  background-color: #0000;
  border-style: none;
}

select {
  -webkit-appearance: none;
  -moz-appearance: none;
}

select::-ms-expand {
  display: none;
}

select::-ms-value {
  color: currentColor;
}

legend {
  max-width: 100%;
  white-space: normal;
  color: inherit;
  border: 0;
  display: table;
}

::-webkit-file-upload-button {
  font: inherit;
  -webkit-appearance: button;
}

::-webkit-input-placeholder {
  color: inherit;
  opacity: .54;
}

img {
  border-style: none;
}

progress {
  vertical-align: baseline;
}

svg:not(:root) {
  overflow: hidden;
}

audio, canvas, progress, video {
  display: inline-block;
}

@media screen {
  [hidden~="screen"] {
    display: inherit;
  }

  [hidden~="screen"]:not(:active):not(:focus):not(:target) {
    clip: rect(0 0 0 0) !important;
    position: absolute !important;
  }
}

[aria-busy="true"] {
  cursor: progress;
}

[aria-controls] {
  cursor: pointer;
}

[aria-disabled] {
  cursor: default;
}

::selection {
  text-shadow: none;
  color: #fff;
  background-color: #b3d4fc;
}

:focus {
  -webkit-tap-highlight-color: transparent;
}

.flickity-enabled {
  position: relative;
}

.flickity-enabled:focus {
  outline: none;
}

.flickity-viewport {
  height: 100%;
  touch-action: pan-y;
  position: relative;
  overflow: hidden;
}

.flickity-slider {
  width: 100%;
  height: 100%;
  position: absolute;
  left: 0;
}

.flickity-rtl .flickity-slider {
  left: unset;
  right: 0;
}

.flickity-enabled.is-draggable {
  -webkit-tap-highlight-color: transparent;
  user-select: none;
}

.flickity-enabled.is-draggable .flickity-viewport {
  cursor: move;
  cursor: grab;
}

.flickity-enabled.is-draggable .flickity-viewport.is-pointer-down {
  cursor: grabbing;
}

.flickity-cell {
  position: absolute;
  left: 0;
}

.flickity-rtl .flickity-cell {
  left: unset;
  right: 0;
}

.flickity-button {
  color: #333;
  background: #ffffffbf;
  border: none;
  position: absolute;
}

.flickity-button:hover {
  cursor: pointer;
  background: #fff;
}

.flickity-button:focus {
  outline: none;
  box-shadow: 0 0 0 5px #19f;
}

.flickity-button:active {
  opacity: .6;
}

.flickity-button:disabled {
  opacity: .3;
  cursor: auto;
  pointer-events: none;
}

.flickity-button-icon {
  fill: currentColor;
}

.flickity-prev-next-button {
  width: 44px;
  height: 44px;
  border-radius: 50%;
  top: 50%;
  transform: translateY(-50%);
}

.flickity-prev-next-button.previous {
  left: 10px;
}

.flickity-prev-next-button.next {
  right: 10px;
}

.flickity-rtl .flickity-prev-next-button.previous {
  left: auto;
  right: 10px;
}

.flickity-rtl .flickity-prev-next-button.next {
  left: 10px;
  right: auto;
}

.flickity-prev-next-button .flickity-button-icon {
  width: 60%;
  height: 60%;
  position: absolute;
  top: 20%;
  left: 20%;
}

.flickity-page-dots {
  width: 100%;
  text-align: center;
  flex-wrap: wrap;
  justify-content: center;
  display: flex;
  position: absolute;
  bottom: -25px;
}

.flickity-rtl .flickity-page-dots {
  direction: rtl;
}

.flickity-page-dot {
  width: 10px;
  height: 10px;
  cursor: pointer;
  appearance: none;
  text-indent: -9999px;
  background: #33333340;
  border: none;
  border-radius: 50%;
  margin: 0 8px;
  padding: 0;
  display: block;
  overflow: hidden;
}

.flickity-rtl .flickity-page-dot {
  text-indent: 9999px;
}

.flickity-page-dot:focus {
  outline: none;
  box-shadow: 0 0 0 5px #19f;
}

.flickity-page-dot.is-selected {
  background: #333;
}

.main-header {
  z-index: 1;
  background: #473d34;
}

.main-header .grid {
  width: 100%;
}

@media (min-width: 62em) {
  .main-header .grid {
    width: 90%;
  }
}

.main-header .grid .logo__m {
  position: absolute;
  right: 1rem;
}

.main-header .grid .logo__m, .main-header .grid .logo__m > img {
  max-height: 50px;
}

@media (min-width: 62em) {
  .main-header .grid .logo__m {
    display: none;
  }
}

.main-header .nav {
  height: 50px;
  width: 100%;
  pointer-events: none;
  z-index: 9000;
  padding: 1.5rem 0;
  display: block;
  position: relative;
}

@media (min-width: 62em) {
  .main-header .nav {
    height: unset;
  }
}

.main-header .nav__m {
  width: 40px;
  height: 25px;
  cursor: pointer;
  position: absolute;
  top: 50%;
  left: 1rem;
  transform: translateY(-50%);
}

@media (min-width: 62em) {
  .main-header .nav__m {
    pointer-events: none;
    display: none;
  }
}

.main-header .nav__m > span {
  width: 100%;
  height: 3px;
  transform-origin: center;
  will-change: auto;
  background: #f4e4d7;
  transition: all .15s cubic-bezier(.785, .135, .15, .86);
  display: inline-block;
  position: absolute;
}

.main-header .nav__m > span:nth-child(1) {
  top: 0;
}

.main-header .nav__m > span:nth-child(2) {
  top: 50%;
  transform: translateY(-50%);
}

.main-header .nav__m > span:nth-child(3) {
  bottom: 0;
}

.main-header .nav__m.active > span:nth-child(1), .main-header .nav__m.active > span:nth-child(3) {
  margin-top: -1px;
  top: 50%;
}

.main-header .nav__m.active > span:nth-child(1) {
  left: -1px;
  transform: rotate(45deg)translateY(-50%);
}

.main-header .nav__m.active > span:nth-child(2) {
  opacity: 0;
}

.main-header .nav__m.active > span:nth-child(3) {
  right: -1px;
  transform: rotate(-45deg)translateY(-50%);
}

.main-header .nav > .rudyard-kipling {
  width: 60px;
  height: 60px;
  pointer-events: all;
  margin-left: 1.5rem;
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
}

.main-header .nav > .rudyard-kipling img {
  width: 60px;
  height: 60px;
}

.main-header .nav > ul {
  width: 100%;
  opacity: 0;
  will-change: auto;
  background: #473d34;
  flex-flow: column wrap;
  justify-content: center;
  margin: 0;
  padding-bottom: 1rem;
  padding-left: 0;
  list-style: none;
  transition: opacity .15s cubic-bezier(.785, .135, .15, .86);
  display: flex;
  position: absolute;
  top: 50px;
}

.main-header .nav > ul.active {
  opacity: 1;
}

.main-header .nav > ul.active > li > a {
  pointer-events: all;
}

@media (min-width: 62em) {
  .main-header .nav > ul {
    opacity: 1;
    top: unset;
    background: none;
    flex-direction: row;
    padding: 0;
    transition: none;
    position: relative;
  }
}

.main-header .nav > ul > li {
  pointer-events: none;
}

.main-header .nav > ul > li > a {
  text-transform: uppercase;
  letter-spacing: .023rem;
  color: #f4e4d7;
  pointer-events: none;
  margin: 0 .5rem;
  padding: 1rem;
  font-family: Playfair Display, serif;
  font-size: 1.33333em;
  font-weight: 400;
  display: inline-block;
  position: relative;
}

@media (min-width: 62em) {
  .main-header .nav > ul > li > a {
    pointer-events: all;
    padding: .5rem 1rem;
    display: block;
  }
}

.main-header .nav > ul > li > a:before {
  content: "";
  width: calc(100% - 2rem);
  height: 0;
  opacity: 0;
  will-change: height, opacity, bottom;
  border-top: 3px solid #c8beb5;
  border-bottom: 1px solid #c8beb5;
  transition: all .25s cubic-bezier(.785, .135, .15, .86);
  display: block;
  position: absolute;
  bottom: 0;
  left: 1rem;
}

@media (min-width: 62em) {
  .main-header .nav > ul > li > a:before {
    width: 70%;
    left: 50%;
    transform: translateX(-50%);
  }
}

.main-header .nav > ul > li > a:hover:before, .main-header .nav > ul > li > a.active:before {
  opacity: 1;
  height: 10px;
  bottom: -6px;
}

*, :after, :before {
  outline: none;
  position: relative;
}

html {
  font-family: Raleway, sans-serif;
  font-size: 15px;
}

html, body {
  height: 100%;
}

body {
  background: #f4e4d7;
}

.grid, section {
  width: 90%;
  max-width: 1546px;
  margin: 0 auto;
  padding: 0;
}

.tc {
  margin-top: 2rem;
}

@media (min-width: 62em) {
  .tc {
    margin-top: 0;
  }
}

main > .tc {
  margin-top: 2rem;
}

p, ul, ol, blockquote {
  letter-spacing: .023rem;
  margin-bottom: 1.55em;
}

h1, h2, h3, h4, h5, h6 {
  letter-spacing: .023rem;
  font-family: Playfair Display, serif;
}

h1, .title_h1 {
  margin-top: 0;
  font-size: 1.66667em;
}

@media (min-width: 48em) {
  h1, .title_h1 {
    font-size: 2em;
  }
}

h1 small, .title_h1 small {
  margin-top: .15em;
  font-size: 55%;
  display: block;
}

h2, .title_h2 {
  font-size: 1.46667em;
}

@media (min-width: 48em) {
  h2, .title_h2 {
    font-size: 1.66667em;
  }
}

h3, .title_h3 {
  font-size: 1.33333em;
}

@media (min-width: 48em) {
  h3, .title_h3 {
    font-size: 1.46667em;
  }
}

h4, .title_h4 {
  font-size: 1em;
}

@media (min-width: 48em) {
  h4, .title_h4 {
    font-size: 1.33333em;
  }
}

h5, .title_h5 {
  text-transform: uppercase;
  font-size: 1.13333em;
}

h5 + h2, h5 + .title_h2, h5 + h3, h5 + .title_h3, .title_h5 + h2, .title_h5 + .title_h2, .title_h5 + h3, .title_h5 + .title_h3 {
  margin-top: 0;
}

strong {
  font-weight: 700;
}

small {
  font-size: .733333em;
}

sup {
  vertical-align: super;
  font-size: .75em;
  top: 0;
}

a {
  letter-spacing: .03em;
  color: #f4e4d7;
  font-weight: 600;
  text-decoration: none;
}

img {
  max-width: 100%;
}

figure img {
  vertical-align: top;
}

figure img.js-lazy {
  opacity: 0;
  will-change: opacity;
  transition: opacity .25s cubic-bezier(.445, .05, .55, .95);
}

figure img.js-lazy.b-loaded {
  opacity: 1;
}

.btn {
  color: #f4e4d7;
  will-change: background;
  cursor: pointer;
  background: #473d34;
  padding: .5rem 1.5rem;
  transition: background .15s cubic-bezier(.445, .05, .55, .95);
  display: inline-block !important;
}

.btn:hover {
  background: #2e2722;
}

._rk-tac {
  text-align: center;
}

.container {
  color: #473d34;
  margin-bottom: 2rem;
}

.gutter {
  max-width: 846px;
  margin: 0 auto;
}

.not-found {
  width: 100%;
  position: relative;
}

.not-found > figure {
  max-width: 300px;
  opacity: .5;
  margin: 0 auto;
}

.not-found > .gutter {
  border: 3px solid #473d34;
  margin: .5rem;
  padding: 1rem;
  display: inline-block;
  left: 50%;
  transform: translateX(-50%);
}

.not-found > .gutter:before {
  content: "";
  width: calc(100% + 1.5rem);
  height: calc(100% + 1.5rem);
  border: 1px solid #473d34;
  display: block;
  position: absolute;
  top: -.75rem;
  left: -.75rem;
}

.not-found > .gutter > div {
  color: #f4e4d7;
  background: #473d34;
  padding: 1rem;
}

.not-found > .gutter > div > a {
  margin-top: 1rem;
  display: block;
}

.footer {
  color: #f4e4d7;
  background: #473d34;
  margin-top: 4rem;
  padding: 2rem 0;
}

.footer--content {
  flex-direction: column;
  justify-content: center;
  margin-bottom: 2rem;
  display: flex;
}

@media (min-width: 62em) {
  .footer--content {
    flex-direction: row;
  }
}

.footer--content .citation {
  flex-flow: column wrap;
  flex: 0 0 100%;
  align-items: center;
  display: flex;
}

@media (min-width: 48em) {
  .footer--content .citation {
    flex-direction: row;
    margin-bottom: 2rem;
  }
}

@media (min-width: 62em) {
  .footer--content .citation {
    flex: none;
    margin-right: 2rem;
  }
}

.footer--content .citation .content {
  font-weight: 300;
}

@media (min-width: 48em) {
  .footer--content .citation .content {
    max-width: 320px;
  }
}

.footer--content .citation .content p {
  margin-bottom: .5rem;
  font-size: 1.2em;
}

.footer--content .citation .content .sig {
  margin-bottom: 1rem;
  font-weight: 700;
}

.footer--content .citation figure {
  margin: 0 auto 1rem;
}

@media (min-width: 48em) {
  .footer--content .citation figure {
    position: absolute;
    bottom: -100%;
    right: 2rem;
  }
}

@media (min-width: 62em) {
  .footer--content .citation figure {
    right: unset;
    bottom: unset;
    margin: 0 0 0 2rem;
    position: relative;
  }
}

.footer--content .citation figure img {
  max-height: 300px;
}

@media (min-width: 48em) {
  .footer--content .citation figure img {
    max-height: 400px;
  }
}

@media (min-width: 48em) {
  .footer--content .citation figure img {
    max-height: 300px;
  }
}

.footer--content .details {
  flex-direction: column;
  justify-content: center;
  display: flex;
}

.footer--content .details h3 {
  margin-bottom: 1rem;
}

@media (min-width: 48em) {
  .footer--content .details p {
    max-width: 320px;
  }
}

.footer .social {
  margin-bottom: 1rem;
}

.footer .social ul, .footer .rgpd ul {
  text-align: center;
  margin: 0;
  padding-left: 0;
  list-style: none;
  display: block;
}

.footer .social ul li, .footer .rgpd ul li {
  margin: 0 .5rem 1rem;
  display: inline-block;
}

/*# sourceMappingURL=404.page.css.map */
