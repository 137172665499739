.footer {
    margin-top: space('x8');
    padding: space('x4') 0;
    color: $rk-kennie-light;
    background: $rk-kennie-dark;

    &--content {
        display: flex;
        flex-direction: column;
        justify-content: center;
        margin-bottom: space('x4');

        @media #{md(m)} {
            flex-direction: row;
        }

        .citation {
            display: flex;
            flex-wrap: wrap;
            flex-direction: column;
            flex: 0 0 100%;
            align-items: center;

            @media #{md(s)} {
                flex-direction: row;
                margin-bottom: space(x4);
            }

            @media #{md(m)} {
                flex: 0 0 auto;
                margin-right: space('x4');
            }

            .content {
                font-weight: 300;

                @media #{md(s)} {
                    max-width: 320px;
                }

                p {
                    margin-bottom: space('x1');
                    font-size: fz('display-1')
                }

                .sig {
                    margin-bottom: space('x2');
                    font-weight: 700;
                }

            }

            figure {
                margin: 0 auto space(x2) auto;

                @media #{md(s)} {
                    position: absolute;
                    right: space(x4);
                    bottom: -100%;
                }

                @media #{md(m)} {
                    position: relative;
                    margin: 0 0 0 space(x4);
                    right: unset;
                    bottom: unset;
                }

                img {
                    max-height: 300px;

                    @media #{md(s)} {
                        max-height: 400px;
                    }

                    @media #{md(s)} {
                        max-height: 300px;
                    }
                }
            }
        }

        .details {
            display: flex;
            flex-direction: column;
            justify-content: center;

            h3 {
                margin-bottom: space('x2');
            }

            p {
                @media #{md(s)} {
                    max-width: 320px;
                }
            }
        }
    }

    .social {
        margin-bottom: space('x2');
    }

    .social, .rgpd {
        ul {
            @include reset-ul;
            display: block;
            text-align: center;

            li {
                display: inline-block;
                margin: 0 space('x1') space('x2') space('x1');
            }
        }
    }
}