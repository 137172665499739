.container {
    margin-bottom: space('x4');
    color: $rk-kennie-dark;
}

.gutter {
    margin: 0 auto;
    max-width: 846px;
}

/*
                             |
                            |  
                         |   
                       /   |  )
                       (   . /
                        \ .  )
 __                       |/
(__ `-._                _____
   `-._ `-._          .'     `.
       `-._ `-._     .=========.
           `._ /`-..-          .
              `-._             .
                  `-.._______.'

*/

// Lost something ?
.not-found {
    position: relative;
    width: 100%;

    > figure {
        max-width: 300px;
        margin: 0 auto;
        opacity: .5;
    }

    > .gutter {
        display: inline-block;
        margin: space('x1');
        padding: space('x2');
        border: 3px solid $rk-kennie-dark;
        left: 50%;
        transform: translateX(-50%);

        &:before {
            position: absolute;
            content: '';
            display: block;
            width: calc(100% + #{space('x3')});
            height: calc(100% + #{space('x3')});
            border: 1px solid $rk-kennie-dark;
            top: calc(-#{space('x3')} / 2);
            left: calc(-#{space('x3')} / 2);
        }

        > div {
            padding: space('x2');
            background: $rk-kennie-dark;
            color: $rk-kennie-light;

            > a {
                display: block;
                margin-top: space('x2');
            }
        }
    }
}
