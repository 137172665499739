@use "sass:math";

@mixin clearfix {

	&:after {
		content: "";
		clear: both;
		display: block;
	}
}

@mixin fontfaces($webfonts, $dir) {

	@each $webfont in $webfonts {

		@font-face {
			font-family: nth($webfont, 1);
			src: url("#{$dir}#{nth($webfont, 2)}.woff2") format("woff2"),
					 url("#{$dir}#{nth($webfont, 2)}.woff") format("woff"),
					 url("#{$dir}#{nth($webfont, 2)}.ttf") format("truetype");
			font-weight: #{nth($webfont, 3)};
			font-style: #{nth($webfont, 4)};
		}
	}
}

@mixin reset-ul {
	margin: 0;
	padding-left: 0;
	list-style: none;
}

@mixin antialiased {
	-webkit-font-smoothing: antialiased;
	-moz-font-smoothing: antialiased;
	font-smoothing: antialiased;
	-moz-osx-font-smoothing: grayscale;
	-moz-osx-font-smoothing: antialiased;
}

@mixin triangle-up($triangle-up-width, $triangle-up-height, $triangle-up-color) {
	width: 0;
	height: 0;
	border-color: transparent transparent $triangle-up-color;
	border-style: solid;
	border-width: 0 $triangle-up-width * 0.5 $triangle-up-height;
}

@mixin triangle-up-corner($triangle-up-width, $triangle-up-height, $triangle-up-color) {
	width: 0;
	height: 0;
	border-color: transparent transparent $triangle-up-color;
	border-style: solid;
	border-width: 0 $triangle-up-width $triangle-up-height;
}

@mixin triangle-right($triangle-right-width, $triangle-right-height, $triangle-right-color) {
	width: 0;
	height: 0;
	border-color: transparent transparent transparent $triangle-right-color;
	border-style: solid;
	border-width: $triangle-right-height * 0.5 0 $triangle-right-height * 0.5 $triangle-right-width;
}

@mixin triangle-right-corner($triangle-right-width, $triangle-right-height, $triangle-right-color) {
	width: 0;
	height: 0;
	border-color: transparent transparent transparent $triangle-right-color;
	border-style: solid;
	border-width: $triangle-right-height 0 $triangle-right-height $triangle-right-width;
}

@mixin triangle-down($triangle-down-width, $triangle-down-height, $triangle-down-color) {
	width: 0;
	height: 0;
	border-color: $triangle-down-color transparent transparent;
	border-style: solid;
	border-width: $triangle-down-height $triangle-down-width * 0.5 0;
}

@mixin triangle-down-corner($triangle-down-width, $triangle-down-height, $triangle-down-color) {
	width: 0;
	height: 0;
	border-color: $triangle-down-color transparent transparent;
	border-style: solid;
	border-width: $triangle-down-height $triangle-down-width 0;
}

@mixin triangle-left($triangle-left-width, $triangle-left-height, $triangle-left-color) {
	width: 0;
	height: 0;
	border-color: transparent $triangle-left-color transparent transparent;
	border-style: solid;
	border-width: $triangle-left-height * 0.5 $triangle-left-width $triangle-left-height * 0.5 0;
}

@mixin triangle-left-corner($triangle-left-width, $triangle-left-height, $triangle-left-color) {
	width: 0;
	height: 0;
	border-color: transparent $triangle-left-color transparent transparent;
	border-style: solid;
	border-width: $triangle-left-height $triangle-left-width $triangle-left-height 0;
}

@mixin padding-hack($svg-width, $svg-height, $container-width) {
	height: 0;
	width: $container-width;
	padding-top: strip-units(math.div($svg-height, $svg-width) * $container-width) * 1%;
}

/**
 * Mixin printing a linear-gradient
 * as well as a plain color fallback
 * and the `-webkit-` prefixed declaration
 * @param {String | List | Angle} $direction - Linear gradient direction
 * @param {Arglist} $color-stops - List of color-stops composing the gradient
 */
 @mixin linear-gradient($direction, $color-stops...) {
	@if is-direction($direction) == false {
		$color-stops: ($direction, $color-stops);
		$direction: 180deg;
	}

	background: nth(nth($color-stops, 1), 1);
	background: linear-gradient($direction, $color-stops);
}
