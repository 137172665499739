@use "sass:math";

$white 					: #fff;
$black 					: #000;
$grey-dark				: #2d2d2d;
$grey 					: #5b5b5b;
$grey-mid 				: #2d2d2d;
$grey-light 			: #eee;
$garter-blue            : #d1eef4;
$garter-blue-dark       : #001c4b;
$rk-kennie-light        : #f4e4d7;
$rk-kennie-mid          : #c8beb5;
$rk-kennie-dark         : #473d34;
$rk-kennie-xdark        : #2e2722;
$rites                  : #ecbe59;
$red                    : #c76363;

$box-shadow-light: 0 1px 3px rgba(0,0,0,0.12), 0 1px 2px rgba(0,0,0,0.24);
$box-shadow: 0 10px 20px rgba(0,0,0,0.19), 0 6px 6px rgba(0,0,0,0.23);

$font-size: 15px;
$line-height: 1.55;
$font-color: $black;
$selection-bg: $grey-dark;
$selection-color: #fff;

$breakpoints: (
	"xxs":    0,
	"xs":   480,
	"s":    768,
	"m":    992,
	"l":   1280,
	"xl":  1440,
	"xxl": 1920,
);

$layers: (
	"goku"     : 9000,
	"modal"    : 200,
	"dropdown" : 100,
	"default"  : 1,
	"limbo"    : -999
);

$in-quad: cubic-bezier(0.550, 0.085, 0.680, 0.530);
$out-quad: cubic-bezier(0.250, 0.460, 0.450, 0.940);
$in-out-quad: cubic-bezier(0.455, 0.030, 0.515, 0.955);

$in-cubic: cubic-bezier(0.550, 0.055, 0.675, 0.190);
$out-cubic: cubic-bezier(0.215, 0.610, 0.355, 1.000);
$in-out-cubic: cubic-bezier(0.645, 0.045, 0.355, 1.000);

$in-quart: cubic-bezier(0.895, 0.030, 0.685, 0.220);
$out-quart: cubic-bezier(0.165, 0.840, 0.440, 1.000);
$in-out-quart: cubic-bezier(0.770, 0.000, 0.175, 1.000);

$in-quint: cubic-bezier(0.755, 0.050, 0.855, 0.060);
$out-quint: cubic-bezier(0.230, 1.000, 0.320, 1.000);
$in-out-quint: cubic-bezier(0.860, 0.000, 0.070, 1.000);

$in-sine: cubic-bezier(0.470, 0.000, 0.745, 0.715);
$out-sine: cubic-bezier(0.390, 0.575, 0.565, 1.000);
$in-out-sine: cubic-bezier(0.445, 0.050, 0.550, 0.950);

$in-expo: cubic-bezier(0.950, 0.050, 0.795, 0.035);
$out-expo: cubic-bezier(0.190, 1.000, 0.220, 1.000);
$in-out-expo: cubic-bezier(1.000, 0.000, 0.000, 1.000);

$in-circ: cubic-bezier(0.600, 0.040, 0.980, 0.335);
$out-circ: cubic-bezier(0.075, 0.820, 0.165, 1.000);
$in-out-circ: cubic-bezier(0.785, 0.135, 0.150, 0.860);

$in-back: cubic-bezier(0.600, -0.280, 0.735, 0.045);
$out-back: cubic-bezier(0.175, 00.885, 0.320, 1.275);
$in-out-back: cubic-bezier(0.680, -0.550, 0.265, 1.550);

$out-back-t: cubic-bezier(0.535, 1.650, 0.635, 1.005);

$spaces-base: math.div(8px, 16px) * 1rem;
$spaces: (
	'0': 0,
	'x1': $spaces-base,
	'x2': $spaces-base * 2,
	'x3': $spaces-base * 3,
	'x4': $spaces-base * 4,
	'x5': $spaces-base * 5,
	'x6': $spaces-base * 6,
	'x7': $spaces-base * 7,
	'x8': $spaces-base * 8,
	'x10': $spaces-base * 10,
	'x12': $spaces-base * 12,
	'x14': $spaces-base * 14,
	'x15': $spaces-base * 15,
	'x16': $spaces-base * 16,
	'x18': $spaces-base * 18,
	'x20': $spaces-base * 20,
	'x22': $spaces-base * 22,
	'x24': $spaces-base * 24,
	'x26': $spaces-base * 26,
	'x28': $spaces-base * 28,
	'x30': $spaces-base * 30,
	'x32': $spaces-base * 32,
	'x34': $spaces-base * 34,
	'x36': $spaces-base * 36,
	'x38': $spaces-base * 38,
	'auto': auto,
);

$font-sizes: (
  'big-s' : 50px,
  'big' : 85px,
  'big-2' : 100px,
  'display-4': 32px,
  'display-3': 27px,
  'display-2': 20px,
  'display-1': 18px,
  'h5': 17px,
  'h4': 20px,
  'h3': 22px,
  'h2': 25px,
  'h1': 30px,
  'body': 15px,
  'medium': 13px,
  'small': 11px,
);

$banner-preload-height: 500px;
