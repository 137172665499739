/**
 * A function helper to avoid having to type `map-get($layers, ...)`
 * Based on http://css-tricks.com/handling-z-index/
 * @param  {string} $layer The name of the z-index
 * @param  {number} $var   The modifier if needed
 * @return {number}        The corresponding z-index based on the $layers var
 */
@use "sass:math";

@function z($layer, $var: 0) {
    @if not map-has-key($layers, $layer) {
        @error "No z-index found in $layers map for `#{$layer}`. Property omitted.";
    }

    $value: map-get($layers, $layer);
    @return $value + $var;
}

/**
 * A function helper to avoid having to type `map-get($font-families, ...)` to output a given font-family
 * @param  {string} $font-family The name of the font-family
 * @return {string}              The corresponding font-family declaration
 */
@function ff($font-family) {
	@if not map-has-key($font-families, $font-family) {
        @error "No font-family found in $font-families map for `#{$font-family}`. Property omitted.";
    }

	$value: map-get($font-families, $font-family);
	@return $value;
}

/**
 * Create variables for the media queries
 * @param  {string} $breakpoint The breakpoint
 * @param  {string} $type       Type of media query (min or max)
 * @param  {string} $unit       The unit for the media queries (em or px)
 * @return {string}             A media query expression
 */
@function md($breakpoint, $type:"min", $unit:"em") {

	@if not map-has-key($breakpoints, $breakpoint) {
        @warn "No breakpoint found in $breakpoints map for `#{$breakpoint}`. Property omitted.";
    }

	$value: map-get($breakpoints, $breakpoint);

	@if math.div($value, $value) != 1 and $value != 0 {
		@return $value;
    }

	@if $unit == "em" {

		$size: math.div($value, 16) * 1em;

		@if $type == "min" {
			@return "(min-width: #{$size})";
		}
		@if $type == "max" {
			@return "not all and (min-width: #{$size})";
		}
	}

	@if $unit == "px" {

		$size: $value * 1px;

		@if $type == "min" {
			@return "(min-width: #{$size})";
		}
		@if $type == "max" {
			@return "not all and (min-width: #{$size})";
		}
	}
}

/**
 * A function helper to facilitate the usage of the different
 * spaces accross the whole projet.
 *
 * @param  {string} $size The name of the wanted size in the $spaces map
 * @return {string}       The corresponding size
 */
@function space($space) {
	@if not map-has-key($spaces, $space) {
		@warn "No size found in $spaces map for `#{$space}`. Property omitted.";
	}

	@return map-get($spaces, $space);
}

/**
 * A function helper to avoid having to type `map-get($layers, ...)`
 * Based on http://css-tricks.com/handling-z-index/
 *
 * @param  {string} $layer The name of the z-index
 * @param  {number} $var   The modifier if needed
 * @return {number}        The corresponding z-index based on the $layers var
 */
@function fz($font-size, $unit: "em") {
    @if not map-has-key($font-sizes, $font-size) {
      @warn "No font-size found in $fonti-sizes map for `#{$font-size}`. Property omitted.";
      @return "inherit";
    }
  
    $root-font-size: 15px;
  
    $value: map-get($font-sizes, $font-size);
    @return #{math.div($value, $root-font-size) + $unit};
}
