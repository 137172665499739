// Variables and reset first to be usable everywhere
@import "base/all";

// Tools
@import "tools/all";

// Layout
@import "layout/header";
@import "layout/main";
@import "layout/insections";
@import "layout/footer";
