*,
*:after,
*:before {
	position: relative;
	outline: none;
}

html {
    font-family: 'Raleway', sans-serif;
	font-size: $font-size;
}

html, body {
    height: 100%;
}

body {
    background: $rk-kennie-light;
}

.grid,
section {
    width: 90%;
    max-width: 1546px;
    padding: 0;
    margin: 0 auto;
}

.tc {
    margin-top: space(x4);

    @media #{md(m)} {
        margin-top: 0;
    }
}

main > .tc {
    margin-top: space(x4);
}

p,
ul,
ol,
blockquote {
	margin-bottom: $line-height * 1em;
    letter-spacing: 0.023rem;
}

h1,
h2,
h3,
h4,
h5,
h6 {
    font-family: 'Playfair Display', serif;
    letter-spacing: 0.023rem;
}

h1, .title_h1 {
	margin-top: 0;
	font-size: fz('h2');

	@media #{md('s')} {
		font-size: fz('h1');
	}

	small {
		display: block;
		margin-top: .15em;
		font-size: 55%;
	}
}

h2, .title_h2 {
	font-size: fz('h3');

	@media #{md('s')} {
		font-size: fz('h2');
	}
}

h3, .title_h3 {
	font-size: fz('h4');

	@media #{md('s')} {
		font-size: fz('h3');
	}
}

h4, .title_h4 {
	font-size:  fz('body');

	@media #{md('s')} {
		font-size: fz('h4');
	}
}
h5, .title_h5 {
	font-size:  fz('h5');
	text-transform: uppercase;

	& + h2,
	& + .title_h2,
	& + h3,
	& + .title_h3 {
		margin-top: 0;
	}
}

strong {
	font-weight: 700;
}

small {
	font-size: fz('small');
}

sup {
	vertical-align: super;
    font-size: 0.75em;
    top: 0;
}

a {
	text-decoration: none;
    font-weight: 600;
    letter-spacing: .03em;
    color: $rk-kennie-light;
}

img {
    max-width: 100%;
}

// Image in figure default space hack
figure {
    img {
        vertical-align: top;
    }
}

figure {
    img {
        &.js-lazy {
            opacity: 0;
            will-change: opacity;
            transition: opacity .25s $in-out-sine;

            &.b-loaded { opacity: 1; }
        }
    }
}

// Buttons
.btn {
    display: inline-block !important;
    padding: space('x1') space('x3');
    background: $rk-kennie-dark;
    color: $rk-kennie-light;
    will-change: background;
    transition: background .15s $in-out-sine;
    cursor: pointer;

    &:hover {
        background: $rk-kennie-xdark;
    }
}

// Alignments
._rk {
    &-tac { text-align: center; }
}
