.main-header {
    background: $rk-kennie-dark;
    z-index: z('default');

    .grid {
        width: 100%;

        @media #{md(m)} {
            width: 90%;
        }

        .logo__m {
            position: absolute;
            right: space(x2);

            &, > img {
                max-height: 50px;
            }

            @media #{md(m)} {
                display: none;
            }
        }
    }

    .nav {
        position: relative;
        height: 50px;
        display: block;
        width: 100%;
        padding: space('x3') 0;
        pointer-events: none;
        z-index: z('goku');

        @media #{md(m)} {
            height: unset;
        }

        // Burger menu
        &__m {
            position: absolute;
            width: 40px;
            height: 25px;
            top: 50%;
            left: space(x2);
            transform: translateY(-50%);
            cursor: pointer;

            @media #{md(m)} {
                display: none;
                pointer-events: none;
            }

            > span {
                position: absolute;
                display: inline-block;
                width: 100%;
                height: 3px;
                background: $rk-kennie-light;
                transform-origin: center center;
                transition: all .15s $in-out-circ;
                will-change: auto;
                
                &:nth-child(1) {
                    top: 0;
                }

                &:nth-child(2) {
                    top: 50%;
                    transform: translateY(-50%);
                }

                &:nth-child(3) {
                    bottom: 0;
                }
            }

            &.active {
                > span {
                    &:nth-child(1), &:nth-child(3) {
                        top: 50%;
                        margin-top: -1px;
                    }

                    &:nth-child(1) {
                        left: -1px;
                        transform: rotate(45deg) translateY(-50%);
                    }

                    &:nth-child(2) {
                        opacity: 0;
                    }

                    &:nth-child(3) {
                        right: -1px;
                        transform: rotate(-45deg) translateY(-50%);
                    }
                }
            }
        }

        // Our main logo
        > .rudyard-kipling {
            position: absolute;
            margin-left: space('x3');
            width: 60px;
            height: 60px;
            pointer-events: all;
            top: 50%;
            transform: translateY(-50%);

            img {
                width: 60px;
                height: 60px;
            }
        }

        // The navigation
        > ul {
            @include reset-ul;
            position: absolute;
            display: flex;
            width: 100%;
            padding-bottom: space(x2);
            flex-direction: column;
            flex-wrap: wrap;
            justify-content: center;
            background: $rk-kennie-dark;
            opacity: 0;
            transition: opacity .15s $in-out-circ;
            will-change: auto;
            top: 50px;

            &.active {
                opacity: 1;

                > li > a {
                    pointer-events: all;
                }
            }

            @media #{md(m)} {
                position: relative;
                padding: 0;
                flex-direction: row;
                background: transparent;
                transition: none;
                opacity: 1;
                top: unset;
            }

            > li {
                pointer-events: none;

                > a {
                    position: relative;
                    display: inline-block;
                    margin: 0 space(x1);
                    padding: space(x2);
                    font-family: 'Playfair Display', serif;
                    font-size: fz(display-2);
                    font-weight: 400;
                    text-transform: uppercase;
                    letter-spacing: .023rem;
                    color: $rk-kennie-light;
                    pointer-events: none;

                    @media #{md(m)} {
                        display: block;
                        padding: space(x1) space(x2);
                        pointer-events: all;
                    }

                    &:before {
                        position: absolute;
                        display: block;
                        content: '';
                        width: calc(100% - #{space(x4)});
                        height: 0;
                        border-top: 3px solid $rk-kennie-mid;
                        border-bottom: 1px solid $rk-kennie-mid;
                        bottom: 0;
                        left: space(x2);
                        opacity: 0;
                        will-change: height, opacity, bottom;
                        transition: all .25s $in-out-circ;

                        @media #{md(m)} {
                            width: 70%;
                            left: 50%;
                            transform: translateX(-50%);
                        }
                    }

                    &:hover, &.active {
                        &:before {
                            opacity: 1;
                            height: 10px;
                            bottom: -6px;
                        }
                    }
                }
            }
        }
    }
}
